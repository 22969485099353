import React from "react"
import { Link } from "gatsby"

export default function About() {
	return (
		<div>
			<p>about</p>
			<Link to="/">home</Link>
		</div>
	)
}
